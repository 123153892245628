import _objectSpread from "D:/Development/Laravel/genic-drive-updated/frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import ObjectPath from "object-path";
import { QUERY } from "@/core/services/store/request.module";
import { SEARCH_ENABLED, SEARCH_TITLE } from "@/core/services/store/common.module";
import { EventBus } from "@/core/event-bus/event.bus";
import { last, head, pickBy, identity } from "lodash";
import JwtService from "@/core/services/jwt.service";
export default {
  data: function data() {
    return {
      endpoint: "activity",
      pagination: {
        total_rows: null,
        total_pages: null,
        current_page: null,
        offset: null,
        page_limit: null
      },
      rows: [],
      loading: false,
      filter: {
        "page-no": 1,
        "page-limit": 50,
        search: null,
        "start-date": null,
        "end-date": null
      }
    };
  },
  watch: {
    $route: function $route() {
      this.get_rows({
        do_export: false
      });
    }
  },
  methods: {
    do_menu_action: function do_menu_action(action) {
      switch (action) {
        case "export":
          this.get_rows({
            do_export: true
          });
          break;
      }
    },
    update_pagination: function update_pagination(param) {
      var _this2 = this;

      this.$router.push({
        name: this.endpoint,
        query: _objectSpread(_objectSpread({}, this.$route.query), {}, {
          t: new Date().getTime(),
          "page-no": param
        })
      }).then(function () {
        _this2.get_rows({
          do_export: false
        });
      });
    },
    get_rows: function get_rows(_ref) {
      var _this3 = this;

      var do_export = _ref.do_export;

      if (this.loading) {
        return false;
      }

      this.loading = true;
      this.filter = _objectSpread({}, this.$route.query);
      this.filter["page-no"] = ObjectPath.get(this.$route, "query.page-no", 1) || 1;
      this.filter["page-limit"] = ObjectPath.get(this.$route, "query.page-limit", 20) || 20;
      this.filter["start-date"] = ObjectPath.get(this.$route, "query.start-date", null);
      this.filter["end-date"] = ObjectPath.get(this.$route, "query.end-date", null);
      this.filter["search"] = ObjectPath.get(this.$route, "query.search", null);
      this.filter["search-owner"] = ObjectPath.get(this.$route, "query.search-owner", null);
      this.filter["search-owned-by"] = ObjectPath.get(this.$route, "query.search-owned-by", null);
      this.filter["search-date-modified"] = ObjectPath.get(this.$route, "query.search-date-modified", null);
      this.filter["search-item-name"] = ObjectPath.get(this.$route, "query.search-item-name", null);
      this.filter["search-start-date"] = ObjectPath.get(this.$route, "query.search-start-date", null);
      this.filter["search-end-date"] = ObjectPath.get(this.$route, "query.search-end-date", null);
      this.filter["do-export"] = +!!do_export;

      if (do_export) {
        this.filter["token"] = JwtService.getToken();
        var filter = pickBy(this.filter, identity);
        var url_search_params = new URLSearchParams(filter).toString();
        var api_url = "".concat(this.$apiURL, "activity-log?").concat(url_search_params);
        this.loading = false;
        window.open(api_url, "_blank");
        return false;
      }

      this.$store.dispatch(QUERY, {
        url: "activity-log",
        data: this.filter
      }).then(function (response) {
        _this3.rows = response.rows;
        _this3.pagination.total_rows = response["total"];
        _this3.pagination.total_pages = Math.ceil(response["total"] / response["page-limit"]);
        _this3.pagination.current_page = response["page-no"];
        _this3.pagination.offset = response["offset"];
        _this3.pagination.page_limit = response["page-limit"];
      }).catch(function (error) {
        console.log({
          error: error
        });
      }).finally(function () {
        _this3.loading = false;

        _this3.$nextTick(function () {
          var scrollableDiv = document.getElementById("scrollable-div");
          scrollableDiv.scroll({
            top: 0,
            behavior: "smooth"
          });
        });
      });
    }
  },
  beforeDestroy: function beforeDestroy() {
    EventBus.$off("start-search");
    EventBus.$off("date-search");
    EventBus.$off("refresh-listing");
    EventBus.$emit("is-activity-log", false);
    this.$store.dispatch(SEARCH_ENABLED, false);
    this.$store.dispatch(SEARCH_TITLE, null);
  },
  mounted: function mounted() {
    var _this = this;

    _this.get_rows({
      do_export: false
    });

    _this.$store.dispatch(SEARCH_ENABLED, true);

    _this.$store.dispatch(SEARCH_TITLE, "Drive");

    EventBus.$emit("show-search-setting", true);
    EventBus.$emit("is-activity-log", true);
    EventBus.$on("refresh-listing", function () {
      _this.$nextTick(function () {
        _this.get_rows({
          do_export: false
        });
      });
    });
    EventBus.$on("date-search", function (param) {
      _this.filter["start-date"] = head(param);
      _this.filter["end-date"] = last(param);

      _this.get_rows({
        do_export: false
      });
    });
    EventBus.$on("start-search", function (param) {
      _this.filter.search = param;

      _this.$nextTick(function () {
        _this.get_rows({
          do_export: false
        });
      });
    });
  }
};